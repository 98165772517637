.phone-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 90%;
  margin: 10% auto 0 auto;

  .cloud {
    position: absolute;
    top: 0;
    z-index: -1;
  }

  .phone {
    width: 60%;

    img {
      width: 90%;
    }
  }

  .body {
    width: 40%;

    .header {
      display: flex;
      flex-direction: row;
      height: 30%;
      margin-top: -25px;
      margin-bottom: 25px;

      .moon {
        width: 50%;
        padding-top: 8%;

        img {
          width: 90%;
          cursor: pointer;
        }
      }

      .header-text {
        width: 80%;
        text-align: center;
        font-weight: bold;
        color: #303D53;
        font-size: 2.2vw;
      }
    }

    .desci {
      margin-left: -10%;
      height: 40%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .line {
        width: 5px;
        border-radius: 50px;
        height: 80%;
        background-color: #4E5F7C;
        margin-right: 5%;
      }

      .desc-text {
        font-size: 3vw;
        color: #303D53;
        line-height: 2;
        text-align: left;
      }
    }
  }
}