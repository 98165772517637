.omego-header {
  .header-part-one {
    display: flex;
    flex-direction: row;
    height: 20%;
    justify-content: space-between;
    align-items: center;

    .language {
      display: flex;

      img {
        margin-right: 10px;
        width: 8%;
      }

      //.ant-select {
      //  width: 100px !important;
      //}

      .ant-select-selection {
        background-color: transparent;
        border: none;
        margin: 0 !important;
        color: white;
      }


      .ant-select-selection--single .ant-select-selection__rendered {
        margin: 0 !important;
      }

      .ant-select-arrow {
        color: white;
      }
    }

    img {
      width: 26%;
    }
  }

  .header-part-two {
    padding: 0 2%;
    height: 50%;
    display: flex;
    flex-direction: row;

    .virtual-coordinator {
      width: 43%;

      .header {
        font-style: normal;
        font-weight: bold;
        font-size: 2vw;
        line-height: 1.3;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        margin-bottom: 10px;
      }

      .body {
        font-style: normal;
        font-weight: normal;
        font-size: 1.3vw;
        line-height: 1.8;
        letter-spacing: -0.03em;
        color: #FFFFFF;
      }

      .read-more-button {
        width: 44%;
        text-align: center;
        background: #22D7B7;
        box-shadow: 0px 1px 45px rgba(34, 215, 183, 0.3);
        border-radius: 45px;
        font-style: normal;
        padding: 10px 0;
        cursor: pointer;
        font-weight: 500;
        margin-top: 20%;
        font-size: 1.4vw;
        letter-spacing: -0.03em;
        color: #FFFFFF;
      }
    }

    .virtual-images {
      display: flex;
      width: 57%;
      margin-left: -10%;
      flex-direction: row;
      align-items: center;

      img {
        &:first-child {
          width: 30%;
        }

        &:last-child {
          width: 80%;
        }
      }
    }
  }

  .header-part-three {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 30%;

    img {
      &:first-child {
        height: 100%;
      }

      &:last-child {
        width: 15%;
      }
    }
  }
}