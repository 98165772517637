.start-road {
  margin-top: 50px;

  .road-trip {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #303D53;
    font-size: 2.5vw;
    letter-spacing: -0.06em;
  }

  .road-trip-desc {
    display: flex;
    margin-top: 50px;
    width: 100%;
    flex-direction: row;

    .desc {
      width: 50%;
      color: #303D53;

      .header {
        font-weight: bold;
        font-size: 2.4vw;
        margin-bottom: 20px;
        letter-spacing: -0.06em;
      }

      .body {
        font-size: 1vw;
        line-height: 1.8;
      }
    }

    .image {
      width: 60%;
      margin-left: -5%;
      display: flex;
      position: relative;
      align-items: flex-end;

      .first-dot {
        width: 4%;
        height: auto;
        padding-top: 4%;
        background-color: #22D7B7;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        left: 46%;
      }

      .first-cloud {
        width: 30%;
        text-align: center;
        background-color: white;
        border-radius: 20px;
        position: absolute;
        font-size: 0.9vw;
        top: 35%;
        transform: translateX(-50%);
        left: 50%;
        padding: 10px 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .first-cloud {
        width: 30%;
        text-align: center;
        background-color: white;
        border-radius: 20px;
        position: absolute;
        font-size: 0.9vw;
        top: 32%;
        transform: translateX(-50%);
        left: 48%;
        padding: 10px 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 2px solid #f5f5f5;
      }

      .first-cloud:after, .first-cloud:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      .first-cloud:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 15px;
        margin-left: -15px;
      }

      .first-cloud:before {
        border-color: rgba(245, 245, 245, 0);
        border-top-color: #f5f5f5;
        border-width: 15px;
        margin-left: -15px;
      }

      .second-dot {
        cursor: pointer;
        width: 4%;
        height: auto;
        padding-top: 4%;
        background-color: #22D7B7;
        position: absolute;
        border-radius: 50%;
        top: 65%;
        transform: translateX(-50%);
        left: 73%;
      }

      .second-cloud {
        cursor: pointer;
        width: 30%;
        text-align: center;
        background-color: white;
        border-radius: 20px;
        position: absolute;
        font-size: 0.9vw;
        top: 40%;
        transform: translateX(-50%);
        left: 75%;
        padding: 10px 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 2px solid #f5f5f5;
      }

      @media (max-width: 1200px) {
        .second-cloud {
          top: 50%;
        }
      }

      .second-cloud:after, .second-cloud:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      .second-cloud:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 15px;
        margin-left: -15px;
      }

      .second-cloud:before {
        border-color: rgba(245, 245, 245, 0);
        border-top-color: #f5f5f5;
        border-width: 15px;
        margin-left: -15px;
      }

      img {
        width: 100%;
      }

      .dot-one {
        background-color: #22D7B7;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

    }
  }
}