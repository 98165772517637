.mobile-terms {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

}

.mobile-terms-content {
  text-align: justify;
  font-size: 14px;
  color: #666;
  font-weight: 300;
  margin-left: 10px;
  margin-right: 20px;

  .section-header {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #303D53;
    margin-bottom: 30px;
  }


  .regulation-title {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #000000;
  }

  .mobile-terms-ordered-list {
    padding-left: 20px;

    li {
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  .section {
    margin-top: 50px;
  }
}

.mobile-terms-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 80px 105px;

  .content-list {
    width: 28%;
    margin-top: 40px;

    .contents-text {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #1B51DC;
    }

    .point-paragraph {
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;

      display: flex;
      align-items: center;

      color: #5B6F91;

      a {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #5B6F91;
      }

      a:hover {
        color: #1B51DC;
      }
    }

    .point-paragraph:hover {
      color: #1B51DC;
    }
  }
}

.mobile-terms-title {
  margin-top: 30px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  justify-content: center;
}