.calculator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //height: 15%;
  margin-top: 70px;

  .left-calc {
    width: 40%;


    .ant-tooltip-inner {
      color: red !important;
    }

    .nod {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #06265A;
      font-size: 1.1vw;
    }

    .icons {
      display: flex;
      flex-direction: row;
      height: 40%;

      .icon {
        width: 33.33333%;
        text-align: center;

        .value {
          font-size: 1.4vw;
          font-weight: 600;
          color: #06265A;
          margin-bottom: 5px;
        }

        img {
          width: 60%;
        }

        .desc {
          color: #06265A;
          margin-top: 10px;
          font-size: 0.8vw;
          font-weight: 600;
        }
      }

      .icon:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }

      .icon:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .icon:nth-child(3) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .slider {
      width: 100%;
      margin-top: 50px;

      .ant-slider-step {
        background: #06265A;
        height: 2px;
      }

      .ant-slider-track {
        height: 2px;
      }

      .ant-slider-handle {
        border: 2px solid #06265A;
        background-color: #06265A;
      }

      .ant-slider-handle:focus {
        border-color: #06265A;
        box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
      }

      input {
        width: 100%;
      }
    }
  }

  .left-calc:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .right-calc {
    width: 40%;

    .icons {
      display: flex;
      flex-direction: row;
      height: 40%;

      .icon {
        width: 33.33333%;
        text-align: center;

        .value {
          font-size: 1.4vw;
          font-weight: 600;
          color: #06265A;
          margin-bottom: 5px;
        }

        img {
          width: 60%;
        }

        .desc {
          color: #06265A;
          margin-top: 10px;
          font-size: 0.8vw;
          font-weight: 600;
        }
      }

      .icon:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }

      .icon:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .icon:nth-child(3) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .kpd {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #3442CA;
      font-size: 1.1vw;
    }

    .slider {
      margin-top: 50px;
      width: 100%;

      .ant-slider-step {
        background: #3442CA;
        height: 2px;
      }

      .ant-slider-track {
        height: 2px;
      }

      .ant-slider-handle {
        border: 2px solid #3442CA;
        background-color: #3442CA;
      }

      .ant-slider-handle:focus {
        border-color: #3442CA;
        box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
      }

      input {
        width: 100%;
      }
    }
  }

  .right-calc:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}