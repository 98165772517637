* {
  font-family: 'Open Sans', sans-serif;
}

body {
  position: absolute;
}

#calculator {
  width: 100%;
}

.header-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;

  h3 {
    font-weight: bold;
  }

  .container {
    width: 71%;
    margin: 0 auto;


    #calc {

      .exact {
        width: 20%;
        border: 1px solid #3442CA;
        color: #3442CA;
        margin: 0 auto;
        text-align: center;
        padding: 5px 0;
        cursor: pointer;
        font-size: 1.2vw;
        border-radius: 35px;
      }

      .savings-proposal {
        text-align: center;
        padding-top: 10%;
        color: #06265A;

        .tooltip-container {
          color: red !important;
          border: 1px solid red !important;
        }

        .star {
          margin-left: 10px;
          font-size: 2vw;
          cursor: pointer;
          width: 2%;
          background: white;
        }

        .star:hover {
          color: #22D7B7;
        }

        div:nth-child(1) {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 1.5vw;
        }

        div:nth-child(2) {
          margin-bottom: 10px;
          font-size: 1vw;
        }
      }

      .line-container {
        position: relative;


        .follow-line {
          position: absolute;
          left: -10%;
          top: 0;
          width: 2px;
          height: 100%;
          background: rgb(255, 255, 255);
          background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(147, 183, 255, 1) 35%, rgba(255, 255, 255, 1) 100%);
        }

        .follow-dot {
          width: 10px;
          height: auto;
          padding-top: 10px;
          border-radius: 50%;
          background-color: #3B7CFC;
          position: absolute;
          top: 0;
          left: -10.4%;
          z-index: 2;
          box-shadow: 0px 0px 0px 2px rgba(147, 183, 255, 1), 0px 0px 0px 8px rgba(59, 124, 252, 0.28);
        }

        .aware {
          width: 100%;
          margin-top: 100px;
          display: flex;
          flex-direction: row;

          .aware-text {
            width: 100%;
            font-size: 1.5vw;
            text-align: center;
            color: #303D53;
          }

          .sun {
            margin-left: -20%;
            width: 10%;

            img {
              width: 150%;
            }
          }
        }

        .schedule {
          margin-top: 100px;
          width: 100%;
          text-align: center;

          img {
            width: 80%;
          }
        }
      }
    }
  }

  .container-two {
    position: relative;

    .container-element {
      position: absolute;
      top: 0;
      width: 100%;

      .experiences {
        position: relative;


        .back-image-container {
          position: absolute;
          z-index: 2;
          right: 0;
          width: 80%;

          img {
            width: 100%;
          }


          .dot-one {
            position: absolute;
            top: 25%;
            left: 10%;
            border-radius: 50%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #22D7B7;
          }

          .dot-two {
            position: absolute;
            border-radius: 50%;
            top: 15%;
            left: 40%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #22D7B7;
          }

          .dot-three {
            position: absolute;
            border-radius: 50%;
            top: 30%;
            left: 80%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #22D7B7;
          }

          .dot-four {
            position: absolute;
            border-radius: 50%;
            top: 80%;
            left: 60%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #22D7B7;
          }

          .dot-five {
            position: absolute;
            border-radius: 50%;
            top: 60%;
            left: 45%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #22D7B7;
          }

          .dot-six {
            position: absolute;
            border-radius: 50%;
            top: 30%;
            left: 65%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #22D7B7;
          }

          .dot-seven {
            position: absolute;
            border-radius: 50%;
            top: 70%;
            left: 25%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #22D7B7;
          }

          .dot-eight {
            position: absolute;
            border-radius: 50%;
            top: 55%;
            left: 15%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #284F9D;
          }

          .dot-nine {
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 35%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #284F9D;
          }


          .dot-ten {
            position: absolute;
            border-radius: 50%;
            top: 60%;
            left: 80%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #284F9D;
          }

          .dot-eleven {
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 75%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #284F9D;
          }

          .dot-twelve {
            position: absolute;
            border-radius: 50%;
            top: 20%;
            left: 10%;
            width: 1%;
            height: auto;
            padding-top: 1%;
            background-color: #284F9D;
          }
        }

        .body {
          position: absolute;
          top: 0;
          width: 100%;
          background-color: #ECF0F7;


          .experiences-header {
            width: 100%;
            font-weight: bold;
            text-align: center;
            font-size: 3vw;
            color: #153B8A;
            z-index: 4;
            position: relative;
            margin-top: 15%;
            margin-bottom: 10%;
          }

          .first-text {
            display: flex;
            flex-direction: row;
            width: 71%;
            margin: 0 auto;
            z-index: 4;
            position: relative;
            justify-content: flex-start;
            color: #284F9D;

            .text {
              text-align: center;
              width: 45%;
              line-height: 2;

              .text-header {
                font-weight: bold;
                margin-bottom: 20px;
                font-size: 2vw;
              }

              .text-body {
                font-size: 1.3vw;
              }
            }
          }

          .second-text {
            display: flex;
            flex-direction: row;
            width: 71%;
            margin: 20% auto 0 auto;
            color: #284F9D;
            justify-content: flex-end;
            align-items: center;
            z-index: 4;
            position: relative;

            img {
              width: 40%;
              margin-top: -20%;
              margin-right: 15%;
              z-index: 4;
              position: relative;
            }

            .text {
              text-align: right;
              width: 30%;
              font-size: 1.3vw;
              line-height: 1.5;
            }
          }

          .third-text {
            display: flex;
            flex-direction: row;
            margin: 10% auto 0 auto;
            width: 71%;
            color: #284F9D;
            align-items: center;

            .text {
              width: 100%;
              text-align: left;
              font-size: 1.3vw;
              line-height: 1.5;
              margin-right: 10%;
            }

            img {
              width: 70%;
            }
          }

          .fourth-text {
            display: flex;
            flex-direction: row;
            margin: 10% auto 0 auto;
            width: 71%;
            color: #284F9D;
            justify-content: flex-end;
            align-items: center;

            .text {
              width: 29%;
              text-align: right;
              font-size: 1.3vw;
              line-height: 1.5;
            }

            img {
              width: 50%;
              margin-right: 10%;
              margin-bottom: -8%;
              z-index: 2;
            }
          }

          .bottom {
            width: 100%;
            position: relative;
            //overflow: hidden;

            .bottom-background {
              width: 100%;
              position: absolute;
              top: 0;
            }

            .bottom-body {
              position: absolute;
              top: 0;
              width: 71%;
              left: 0;
              right: 0;
              margin: auto;
              color: #303D53;


              .bottom-header {
                text-align: center;
                font-size: 2.5vw;
                font-weight: bold;
                margin-top: 15%;
                margin-bottom: 10%;
              }

              .trusted {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-bottom: 15%;

                img {
                  width: 60%;
                }
              }

              .quote {
                width: 80%;
                position: relative;
                margin: 0 auto;

                img {
                  width: 40%;
                }

                .quote-body {
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  top: 0;
                  font-size: 1.7vw;
                  align-items: flex-start;
                  justify-content: center;
                  height: 100%;
                  width: 100%;
                  padding-left: 10%;


                  .main {
                    width: 48%;
                    font-style: italic;
                    line-height: 2;
                    font-size: 1vw;
                  }

                  .sign {
                    width: 50%;
                    font-weight: bold;
                    font-size: 1vw;
                  }

                  .desc {
                    width: 50%;
                    font-weight: bold;
                    font-size: 0.7vw;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-tooltip-inner {
  display: flex;
  justify-content: center;
  //background: #06265A;
  border-radius: 35px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #FFFFFF;
  padding: 10px 20px;
}