.reminders {
  width: 100%;
  margin-top: 30px;
  padding: 0 10%;

  .reminders-header {
    display: flex;
    flex-direction: row;
    color: #3B7CFC;
    font-size: 1.5vw;
    font-weight: bold;

    img {
      margin-right: 10px;
    }
  }

  .time-one {
    font-weight: bold;
    color: #3B7CFC;
    font-size: 1vw;
    width: 90%;
    text-align: right;
  }

  .reminders-image {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 70%;
      margin: 0 auto;
      display: block;
    }

    .text {
      font-weight: bold;
      color: #3B7CFC;
      font-size: 1.5vw;
    }
  }

  .time-two {
    font-weight: bold;
    color: #3B7CFC;
    font-size: 1vw;
    width: 90%;
    margin-top: 20px;
    text-align: left;
  }

  .reminders-desc {
    color: #3B7CFC;
    text-align: center;
    width: 100%;
    line-height: 2;
    font-size: 1vw;
  }
}