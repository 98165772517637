.mobile-header{
  .header-container {
    width: 100%;
    padding: 5%;
    position: absolute;
    top: 0;

    .name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .name-logo {
        width: 50%;
      }

      .language-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .ant-select {
          width: 120px !important;
        }

        .language-logo {
          width: 20%;
        }

        .ant-select-selection {
          background-color: transparent;
          border: none;
          margin: 0 !important;
          color: white;
        }

        .ant-select-selection:focus {
          box-shadow: none;
        }

        .ant-select-arrow {
          color: white;
        }
      }
    }

    .virtual {
      color: white;
      font-size: 7.5vw;
      margin-top: 10%;
      font-weight: bold;
      text-align: center;
    }

    .intelligent {
      color: white;
      text-align: center;
      font-size: 5vw;
      line-height: 2;
      margin-top: 5%;
    }

    .bus {
      width: 40%;
      margin: 0 auto;
      display: block;
    }

    .read-more {
      width: 60%;
      margin: 0 auto;
      display: block;
    }

    .header-bottom {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;
      left: 0;
      right: 0;
      margin: auto;

      .bike {
        width: 50%;
        justify-self: center;
        padding-right: 25px;
      }

      .message {
        width: 30%;
        margin-bottom: -25%;
        margin-left: 35%;
      }
    }

  }
}