.contact-us {
  position: fixed;
  top: 60%;
  text-orientation: mixed;
  width: 3vw;
  height: 9vw;
  z-index: 3;
  cursor: pointer;
  background-color: #06327C;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  right: 0;

  div {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1vw;
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

  }
}