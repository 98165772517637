.form-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin: auto;
  right: 0;
  height: 35%;
  left: 0;
  border: 1px solid white;

  .container {
    display: flex;
    flex-direction: row;

    .form {
      padding-top: 5%;
      display: flex;
      flex-direction: column;

      .form-header {
        width: 90%;
        border-bottom-right-radius: 80px;
        border-top-right-radius: 80px;
        background-color: #F9FAFB;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #303D53;
        padding: 5% 0;
        font-size: 2.5vw;
        height: 10%;
      }

      .form-body {
        height: 100%;
        width: 100%;
        align-self: flex-end;
        display: flex;
        flex-direction: column;

        .info {
          margin: 5% auto 3% auto;
          font-size: 1vw;
          line-height: 2;
          font-style: normal;
          font-weight: 300;
          letter-spacing: -0.03em;
        }

        .inputs {
          margin-bottom: 50px;
          border: 0;

          .MuiFormControl-root {
            width: 60%;
          }


          .meeting-picker-titles {
            margin-left: 20%;
            padding-right: 12px;
            width: 60%;
            display: flex;
            justify-content: space-between;

            .time-pickers {
              display: flex;

              .start-time-title {
                font-size: 14px;
                line-height: 19px;
                color: #414141;
                margin-right: 20px;
                margin-bottom: 0;
                min-width: 70px;
              }

              .end-time-title {
                font-size: 14px;
                line-height: 19px;
                color: #414141;
                margin-right: 5px;
                margin-bottom: 0;
                min-width: 55.5px;
              }
            }

            .date-title {
              font-size: 14px;
              line-height: 19px;
              color: #414141;
              margin-bottom: 0;
            }

          }

          .meeting-picker-container {
            margin-left: 20%;
            width: 60%;
            display: flex;
            justify-content: space-between;

            .date-picker {
              background-color: #F9FAFB;
              border-radius: 50px;
              height: 56px;
              border: 0;

              .ant-calendar-picker-input {
                background-color: #F9FAFB;
                border-radius: 50px;
                height: 56px;
                border: 0;
                font-size: 16px;
                line-height: 33px;
                /* identical to box height */
                max-width: 208px;
                letter-spacing: -0.03em;
                color: #303D53;
              }

              ::placeholder {
                color: rgba(0, 0, 0, 0.54);
                padding: 0;
                font-size: 1rem;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-weight: 500;
                line-height: 1;
                letter-spacing: 0.00938em;
              }

            }

            .timepicker-cointainer {
              display: flex;

              .start-time-picker {
                height: 56px;
                width: 80px;
                background: #F9FAFB;
                border-bottom-left-radius: 50px;
                border-top-left-radius: 50px;

                .ant-time-picker-input {
                  height: 56px;
                  width: 70px;
                  background: #F9FAFB;
                  border-bottom-left-radius: 50px;
                  border-top-left-radius: 50px;
                  border: 0;
                  font-size: 16px;
                  text-align: center;
                }

                ::placeholder {
                  color: rgba(0, 0, 0, 0.54);;
                }

                svg {
                  display: none;
                }

                .ant-time-picker-icon {
                  display: none;
                }
              }

              .time-separator {
                display: flex;
                align-items: center;
                min-height: 56px !important;
                background-color: #F9FAFB;
                font-size: 16px;
              }

              .end-time-picker {
                height: 56px;
                width: 80px;
                background: #F9FAFB;
                border-bottom-right-radius: 50px;
                border-top-right-radius: 50px;

                .ant-time-picker-input {
                  height: 56px;
                  width: 70px;
                  background: #F9FAFB;
                  border-bottom-right-radius: 50px;
                  border-top-right-radius: 50px;
                  border: 0;
                  font-size: 16px;
                }

                ::placeholder {
                  color: rgba(0, 0, 0, 0.54);;
                }

                .ant-time-picker-icon {
                  display: none;
                }

                svg {
                  display: none;
                }
              }
            }
          }

          .company-size-select {
            margin-top: 15px;
            height: 56px;
            width: 60%;

            background: #F9FAFB;
            border-radius: 50px;

            .ant-select-selection {
              height: 56px;
              background: #F9FAFB;
              border-radius: 50px;
              border: 0;
              display: flex;
              align-items: center;
              font-size: 16px;
            }
          }

          .MuiFilledInput-root {
            border-radius: 40px !important;
            background-color: #F9FAFB !important;
          }

          .MuiFilledInput-underline:before {
            border-bottom: none;
          }

          .MuiFilledInput-underline:after {
            border-bottom: none;
          }

          .submit-button {
            //margin-top: 30px;
            width: 60%;
            height: 56px;
            border-radius: 40px;
            background-color: #1446CD;
            color: white;
            display: flex;
            cursor: pointer;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 1.3vw;
            font-weight: bold;
            margin: 15px auto;
          }
        }
      }


    }

    .team {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      img {
        width: 80%;
        position: absolute;
        top: 0;
        margin-top: 10px;
      }
    }
  }
}

.ant-calendar {
  border-radius: 22px;
}

.ant-time-picker-panel-inner {
  border-radius: 18px;
}

.error-message {
  width: 60%;
  margin: 5px auto 0 auto;
  text-align: left;
  color: red;
  display: block;
}

.error-message-right {
  width: 60%;
  margin: 5px auto 0 auto;
  text-align: right;
  color: red;
  display: block;
}