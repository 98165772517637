.mobile-terms-header {
  background-color: #1B51DC;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;

  .mobile-omego-logo {
    margin-left: 30px;
  }


  .login-language-container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    .login {
      height: 41px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2vw;
      line-height: 41px;
      /* identical to box height */
      text-align: right;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      cursor: pointer;

      .a-link {
        color: white;
      }
    }

    .language {
      margin-top: 5px;
      margin-left: 40px;
      margin-right: 40px;

      img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }

      .ant-select-selection {
        background-color: transparent;
        border: none;
        margin: 0 !important;
        color: white;
      }


      .ant-select-selection--single .ant-select-selection__rendered {
        margin: 0 !important;
      }

      .ant-select-arrow {
        color: white;
      }
    }
  }
}