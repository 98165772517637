.footer {
  width: 100%;
  background-color: #F9FAFB;


  .footer-cont {
    width: 100%;
    margin: 0 auto;
    padding-top: 5%;
    padding-bottom: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .company-info {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: #435575;
      font-size: 1.2vw;

      .dot {
        width: 5px;
        border-radius: 50%;
        background-color: black;
        height: 5px;
        margin: 0 20px;
      }
    }

    .language {
      display: flex;

      img {
        margin-right: 10px;
        width: 8%;
      }

      //.ant-select {
      //  width: 80px !important;
      //}

      .ant-select-selection {
        background-color: transparent;
        border: none;
        margin: 0 !important;
        color: #303D53;
      }

      .ant-select-selection--single .ant-select-selection__rendered {
        margin: 0 !important;
      }

      .ant-select-arrow {
        color: #303D53;
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      align-items: center;
      justify-content: space-between;

      .icon-container {
        width: 20%;
        padding: 5px;
        border: 1px solid black;
        border-radius: 50%;
      }

      img {
        width: 20%;
        cursor: pointer;
      }
    }

  }

  .terms-footer {
    color: #435575;
    text-align: center;
    padding-bottom: 10px;

    a {
      color: #435575;
    }

    a:visited {
      color: #435575;
    }
  }
}