.siri-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: #303D53;

  .container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #303D53;

    .siri {
      canvas {
        width: 100% !important;
      }
    }

    .voice {
      margin-bottom: 20px;
      margin-top: -5%;

      .voice-header {
        text-align: center;
        font-size: 2.2vw;
        font-weight: bold;
        letter-spacing: -0.06em;
      }

      .voice-body {
        text-align: center;
        font-size: 1.1vw;
      }
    }

    .desc {
      text-align: center;
      font-size: 1.1vw;
    }
  }
}