.mobile-privacy-policy {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  .terms-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 80px 105px;

    .content-list {
      width: 28%;
      margin-top: 40px;

      .contents-text {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        display: flex;
        align-items: center;
        color: #1B51DC;
      }

      .point-paragraph {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #5B6F91;

        a {
          cursor: pointer;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          /* identical to box height */

          display: flex;
          align-items: center;

          color: #5B6F91;
        }

        a:hover {
          color: #1B51DC;
        }
      }

      .point-paragraph:hover {
        color: #1B51DC;
      }
    }
  }
}


.mobile-privacy-policy-content {
  margin-left: 20px;
  margin-right: 20px;
  text-align: justify;
  font-size: 14px;
  color: #666;
  font-weight: 300;

  .section-header {
    text-align: left;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #303D53;
    margin-bottom: 30px;
  }

  .mobile-pp-title {
    margin-top: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 44px;
    display: flex;
    align-items: flex-end;
    color: #000000;
    text-align: center;
  }

  .regulation-title {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #000000;
  }

  .mobile-privacy-policy-content-order-list {
    padding-left: 20px;

    li {
      margin-bottom: 20px;
    }
  }

  .section {
    margin-top: 50px;
  }
}