.footer {
  padding: 30px 0 0 0;

  .main-header {
    font-size: 1.7vw;
    font-weight: bold;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .pointer {
    cursor: pointer;
  }

  .social-media-icon {

    svg {
      width: 80px;
      height: 80px;
    }
  }

  .dark-ahref {
    color: rgba(0, 0, 0, 0.65);

    a {
      color: rgba(0, 0, 0, 0.65);
    }

    a:visited {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .omego-fleet-logo-footer {
    width: 160px;
  }

  .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto 50px auto;

    .row-element {
      width: 33.33333333%;
      display: flex;
      flex-direction: column;

      .flag {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 10%;
          min-width: 10%;
          max-width: 10%;
          margin-right: 10px;
        }

        .header {
          font-size: 1.5vw;
          font-weight: bold;
        }

      }

      .text {
        padding-left: calc(10% + 10px);
        padding-top: 15px;

        .desc {
          font-size: 1.2vw;

          div {

            &:first-child {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto 50px auto;

    .row-element {
      width: 33.33333333%;
      display: flex;
      flex-direction: column;

      .flag {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: calc(10% + 10px);

        .header {
          font-size: 1.5vw;
          font-weight: bold;
        }

      }

      .text {
        padding-top: 15px;

        .desc {
          font-size: 1.2vw;
          width: 100%;

          .desc-rec {
            display: flex;
            flex-direction: row;

            .dot-box {
              width: 10%;
              min-width: 10%;
              max-width: 10%;
              margin-right: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              .dot {
                width: 0.3vw;
                height: 0.3vw;
                border-radius: 50%;
                background-color: black;
              }

            }

            .desc-text {

            }

            .desc-text-icons {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              justify-content: flex-start;

              img {
                width: 15%;
                margin-right: 10px;
              }

              a {
                width: 15%;
                margin-right: 10px;

                img {
                  width: 100%;
                }
              }
            }

            &:first-child {
              margin-bottom: 15px;
            }

          }
        }
      }
    }

    .terms-footer {
      color: #435575;
      text-align: center;
      padding-bottom: 10px;

      a {
        color: #435575;
      }

      a:visited {
        color: #435575;
      }
    }
  }
}


@media screen and (max-width: 800px) {

  .main-header {
    width: 85% !important;
  }
  .first-row {
    width: 85% !important;
  }

  .second-row {
    width: 85% !important;
  }


}
