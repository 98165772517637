.mobile-shiftplan {

  .image {
    width: 90%;
    margin-left: -5%;
    display: flex;
    position: relative;
    align-items: flex-end;


    .first-dot {
      width: 4%;
      height: auto;
      padding-top: 4%;
      background-color: #22D7B7;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      left: 46%;
    }

    .first-cloud {
      width: 30%;
      text-align: center;
      background-color: white;
      border-radius: 20px;
      position: absolute;
      font-size: 2vw;
      top: 15%;
      transform: translateX(-50%);
      left: 50%;
      padding: 10px 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .second-dot {
      width: 4%;
      height: auto;
      padding-top: 4%;
      background-color: #22D7B7;
      position: absolute;
      border-radius: 50%;
      top: 65%;
      transform: translateX(-50%);
      left: 73%;
    }

    .second-cloud {
      width: 40%;
      text-align: center;
      background-color: white;
      border-radius: 30px;
      position: absolute;
      font-size: 2vw;
      top: 35%;
      transform: translateX(-50%);
      left: 73%;
      padding: 10px 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .ab {
      width: 100%;
      box-shadow: 0px 4px 10px rgba(4, 45, 120, 0.33);
    }

    .dot-one {
      background-color: #22D7B7;
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }

  }
}