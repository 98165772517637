.mobile-calculators {
  .calculators {
    margin-top: 10%;

    .ant-slider-track {
      background-color: #22D6B6;
    }

    .first-slider {
      width: 70%;
      margin: 0 auto;

      .ant-slider {
        margin-bottom: 0 !important;
      }

      .ant-slider-track {
        background-color: #22D6B6;
      }

      .ant-slider-rail {
        background-color: #06265A;
      }
    }

    .second-slider {
      width: 70%;
      margin: 20% auto 0 auto;

      .ant-slider-track {
        background-color: #22D6B6;
      }

      .ant-slider-rail {
        background-color: #06265A;
      }
    }
  }
}