.success-message-container {

}

.success-message-modal {
  width: 737.05px !important;
  top: 25%;

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-content {
    background: #FFFFFF !important;
    border-radius: 18px !important;
    width: 737.05px !important;
  }

  .ant-modal {
    background: #FFFFFF !important;
    border-radius: 18px !important;
    width: 737.05px !important;
  }

  .ant-modal-body {
    background: #FFFFFF !important;
    border-radius: 18px !important;
    width: 737.05px !important;
    padding: 0;
  }

  .success-body {
    padding: 51px 74px 51px 74px;

    .success-heading {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: -0.06em;
      color: #303D53;
    }

    .success-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #303D53;
    }
  }
}
