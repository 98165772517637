.cookie-message {
  width: 100%;
  position: fixed;
  z-index: 99999 !important;
  bottom: 0;
  background-color: rgba(103, 115, 139, 0.87);
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .cookie-text {
    margin-top: 15px;
    text-align: center;
    color: white;
  }

  .accept-button {
    margin-left: 20px;
    color: white;
  }
}