.company-mobile-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #435575;
  font-size: 3.5vw;
  padding: 40px 20px 30px 20px;
  margin: 0 auto !important;
  text-align: center;

  .country-container {
    margin-top: 50px;
  }

  .mobile-footer-text {
    margin-top: 14px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #435575;
  }

  .country-header {
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    text-indent: 42px;
    color: #435575;
  }

  .office-location-header-mobile {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.03em;
    color: #435575;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .dot {
    width: 5px;
    border-radius: 50%;
    background-color: black;
    height: 5px;
    margin: 0 20px;
  }
}