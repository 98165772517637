.phone-container {
  display: flex;
  flex-direction: row;
  width: 60%;
  position: relative;
  margin: 100px auto 0 auto;

  .cloud {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 30%;
  }

  .phone {
    width: 50%;

    img {
      width: 80%;
    }
  }

  .body {
    width: 50%;

    .header {
      display: flex;
      flex-direction: row;
      height: 30%;

      .moon {
        width: 20%;
        padding-top: 8%;

        img {
          width: 80%;
          cursor: pointer;
        }
      }

      .header-text {
        width: 80%;
        text-align: center;
        font-weight: bold;
        color: #303D53;
        font-size: 2.2vw;
        letter-spacing: -0.06em;
      }
    }

    .desc {
      margin-left: -10%;
      height: 40%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .line {
        width: 5px;
        border-radius: 50px;
        height: 55%;
        background-color: #4E5F7C;
        margin-right: 5%;
      }

      .desc-text {
        font-size: 1.3vw;
        color: #303D53;
      }
    }

    .centered-text {
      display: flex;
      text-align: center;
      justify-content: center;
    }
  }
}