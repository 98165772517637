.maps {
  display: flex;
  flex-direction: row;
  margin: 60px auto 0 auto;

  .map-view {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
      width: 90%;
      align-self: flex-end;
    }

    .map-view-details {
      padding-top: 80px;

      .text {
        width: 100%;
        text-align: right;
        color: #1F293B;
        font-size: 1vw;
      }

      .map-icons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: -5%;

        div {
          width: 25%;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
      }
    }
  }

  .map-desc {
    width: 40%;
    margin-left: -10%;
    margin-top: 5%;

    img {
      width: 100%;
    }
  }
}