.image-slider {
  .cards {
    height: 10%;
    display: flex;
    flex-direction: row;
    padding: 20px 5%;
    justify-content: space-between;
    align-items: center;
    margin-top: -20%;

    .card {
      cursor: pointer;
      width: 30%;
      position: relative;
      background-color: white;
      box-shadow: 0px 0px 80px rgba(24, 53, 108, 0.05);
      border-radius: 15px;

      .card-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          width: 30%;
        }

        .content-title {
          text-align: center;
          color: #A1B2D3;
          font-weight: bold;
          font-size: 1vw;
        }

        .content-desc {
          text-align: center;
          color: #A1B2D3;
          font-size: 0.6vw;

        }
      }
    }

    .card:hover {
      box-shadow: 0px 0px 34px rgba(13, 67, 173, 0.34);
    }

    .card:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .card-selected {
      cursor: pointer;
      box-sizing: border-box;
      width: 35%;
      position: relative;
      background-color: white;
      border-top: 10px solid #1B51DC;
      box-shadow: 0px 0px 34px rgba(13, 67, 173, 0.34);
      border-radius: 15px;

      .card-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 20px 10px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          width: 30%;
        }

        .content-title {
          text-align: center;
          color: #1B51DC;
          font-weight: bold;
          font-size: 1.3vw;
        }

        .content-desc {
          text-align: center;
          color: #1B51DC;
          font-size: 0.8vw;
          line-height: 1.3;

        }
      }
    }

    .card-selected:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  .slide-images {
    height: 30vw;
    margin-top: 40px;
    //padding: 60px 10%;
  }

}