* {
  font-family: 'Open Sans', sans-serif;
}


.mobile-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  img {
    width: 100%;
  }

  //.header-container {
  //  width: 100%;
  //  padding: 5%;
  //  position: absolute;
  //  top: 0;
  //
  //  .name {
  //    display: flex;
  //    flex-direction: row;
  //    justify-content: space-between;
  //
  //    .name-logo {
  //      width: 50%;
  //    }
  //
  //    .language-container {
  //      display: flex;
  //      flex-direction: row;
  //      justify-content: flex-end;
  //
  //      .ant-select {
  //        width: 120px !important;
  //      }
  //
  //      .language-logo {
  //        width: 20%;
  //      }
  //
  //      .ant-select-selection {
  //        background-color: transparent;
  //        border: none;
  //        margin: 0 !important;
  //        color: white;
  //      }
  //
  //      .ant-select-selection:focus {
  //        box-shadow: none;
  //      }
  //
  //      .ant-select-arrow {
  //        color: white;
  //      }
  //    }
  //  }
  //
  //  .virtual {
  //    color: white;
  //    font-size: 7.5vw;
  //    margin-top: 10%;
  //    font-weight: bold;
  //    text-align: center;
  //  }
  //
  //  .intelligent {
  //    color: white;
  //    text-align: center;
  //    font-size: 5vw;
  //    line-height: 2;
  //    margin-top: 5%;
  //  }
  //
  //  .bus {
  //    width: 40%;
  //    margin: 0 auto;
  //    display: block;
  //  }
  //
  //  .read-more {
  //    width: 60%;
  //    margin: 0 auto;
  //    display: block;
  //  }
  //
  //  .header-bottom {
  //    position: absolute;
  //    bottom: 0;
  //    display: flex;
  //    flex-direction: row;
  //    justify-content: space-between;
  //    width: 70%;
  //    left: 0;
  //    right: 0;
  //    margin: auto;
  //
  //    .bike {
  //      width: 50%;
  //      justify-self: center;
  //      padding-right: 25px;
  //    }
  //
  //    .message {
  //      width: 30%;
  //      margin-bottom: -25%;
  //      margin-left: 35%;
  //    }
  //  }
  //
  //}

  .savings {
    padding-top: 10%;

    .savings-header {
      color: #06265A;
      font-weight: bold;
      font-size: 5vw;
      width: 100%;
      position: relative;
      z-index: 2;
      text-align: center;
    }


    .savings-desc {
      color: #06265A;
      font-size: 3vw;
      z-index: 1;
      width: 100%;
      text-align: center;
      line-height: 2;
      position: relative;
    }

    .cloud {
      width: 50%;
      margin-top: -10%;
    }
  }
}

.orange {
  text-align: center;

  .ant-progress-circle-path {
    stroke: #EE5D2E !important;
  }
}

.blue {
  margin-top: 20px;
}

.green {
  margin-right: 50px;

  .ant-progress-circle-path {
    stroke: #22D6B6 !important;
  }
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  padding: 0 20px !important;
  font-size: 2.5vw;
  font-weight: bold;
}

.ant-progress-circle .ant-progress-text {
  padding: 0 20px !important;
  font-size: 2.5vw;
  font-weight: bold;
}

.road-trip {
  color: #06265A;
  font-weight: bold;
  text-align: center;
  margin-top: 20%;
  font-size: 7vw;
}

.text {
  img {
    width: unset;
  }
}

.button {
  width: 44%;
  text-align: center;
  background: #22D7B7;
  box-shadow: 0px 1px 45px rgba(34, 215, 183, 0.3);
  border-radius: 45px;
  font-style: normal;
  padding: 15px 0;
  cursor: pointer;
  font-weight: 500;
  margin: 20% auto 0 auto;
  font-size: 3.5vw;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}

.sp {
  margin-top: 10%;
  font-size: 7vw;
  display: flex;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  width: 100%;
  color: #1B51DC;

  img {
    margin-right: 10px;
  }
}

.sign {
  font-size: 5vw;
  font-weight: bold;
  color: #303D53;
  text-align: center;
  margin-top: 10%;
}

.inputs {
  text-align: center;


  .date-picker-mobile {
    background-color: #F9FAFB;
    border-radius: 50px;
    height: 56px;
    border: 0;
    width: 90%;
    text-align: left;

    .ant-calendar-picker-input {
      background-color: #F9FAFB;
      border-radius: 50px;
      height: 56px;
      border: 0;
      font-size: 16px;
      line-height: 33px;
      /* identical to box height */
      max-width: 208px;
      letter-spacing: -0.03em;
      color: #303D53;
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.00938em;
    }

  }


  .timepicker-title-mobile {
    display: flex;
    width: 90%;
    margin: 10px auto;
    text-indent: 10px;

    p {
      margin-bottom: 0;
      margin-right: 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
      color: #414141;
    }
  }

  .timepicker-cointainer-mobile {
    display: flex;
    width: 90%;
    margin: 0 auto;

    .start-time-picker {
      height: 56px;
      width: 22%;
      background: #F9FAFB;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;

      .ant-time-picker-input {
        height: 56px;
        background: #F9FAFB;
        border: 0;
        font-size: 16px;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }

      ::placeholder {
        color: rgba(0, 0, 0, 0.54);;
      }

      svg {
        display: none;
      }

      .ant-time-picker-icon {
        display: none;
      }
    }


    .time-separator-mobile {
      display: flex;
      align-items: center;
      min-height: 56px !important;
      background-color: #F9FAFB;
      font-size: 16px;
      width: 20px;
    }

    .end-time-picker {
      height: 56px;
      width: 75%;
      background: #F9FAFB;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;

      .ant-time-picker-input {
        height: 56px;
        background: #F9FAFB;
        border: 0;
        font-size: 16px;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
      }

      ::placeholder {
        color: rgba(0, 0, 0, 0.54);;
      }

      .ant-time-picker-icon {
        display: none;
      }

      svg {
        display: none;
      }
    }
  }

  .company-size-select-mobile {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 56px;
    width: 90%;

    background: #F9FAFB;
    border-radius: 50px;

    .ant-select-selection {
      height: 56px;
      background: #F9FAFB;
      border-radius: 50px;
      border: 0;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }

  .MuiFormControl-root {
    width: 90%;
  }

  .MuiFilledInput-root {
    border-radius: 40px !important;
    background-color: #F9FAFB !important;
  }

  .MuiFilledInput-underline:before {
    border-bottom: none;
  }

  .MuiFilledInput-underline:after {
    border-bottom: none;
  }

  .submit-button-mobile {
    width: 50%;
    border-radius: 40px;
    background-color: #1B51DC;
    color: white;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    padding: 3% 0;
    margin: 0 auto;

  }
}

.media {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 60px 60px 60px;
}

.contacts {
  width: 90%;
  margin-top: 15%;
  border-bottom-right-radius: 40px;
  background-color: #F9FAFB;
  border-top-right-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: #06265A;
  font-weight: bold;
  font-size: 7vw;

  img {
    margin-right: 10px;
  }
}

.sign-desc {
  font-size: 3vw;
  font-weight: bold;
  color: #303D53;
  text-align: center;
  margin-top: 5%;
}

.desci-italic {
  color: #303D53;
  text-align: center;
  line-height: 2;
  font-size: 4vw;
  margin-top: 20%;
}

.desci-body {
  color: #38434C;
  text-align: center;
  line-height: 2;
  font-size: 4vw;
  margin-top: 5%;
}

.desci {
  color: #0B2551;
  text-align: center;
  line-height: 2;
  font-size: 4vw;

  margin-top: 5%;
}

.back-mobile {
  background-image: url("../../assets/mobile/back-mobile.svg");
  background-repeat: no-repeat;
}

.cm {
  color: #182E59;
  text-align: center;
  font-size: 4vw;
  line-height: 2;
  margin-top: 5%;

  strong {
    color: #1B51DC;
  }
}

.sw {
  text-align: center;
  font-size: 4vw;
  margin-top: 5%;
  color: #182E59;
  line-height: 2;
}

.image {
  width: 90%;
  margin-left: -5%;
  display: flex;
  position: relative;
  align-items: flex-end;


  .first-dot {
    width: 4%;
    height: auto;
    padding-top: 4%;
    background-color: #22D7B7;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    left: 46%;
  }

  .first-cloud {
    width: 30%;
    text-align: center;
    background-color: white;
    border-radius: 20px;
    position: absolute;
    font-size: 2vw;
    top: 15%;
    transform: translateX(-50%);
    left: 50%;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .second-dot {
    width: 4%;
    height: auto;
    padding-top: 4%;
    background-color: #22D7B7;
    position: absolute;
    border-radius: 50%;
    top: 65%;
    transform: translateX(-50%);
    left: 73%;
  }

  .second-cloud {
    width: 40%;
    text-align: center;
    background-color: white;
    border-radius: 30px;
    position: absolute;
    font-size: 2vw;
    top: 35%;
    transform: translateX(-50%);
    left: 73%;
    padding: 10px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .ab {
    width: 100%;
    box-shadow: 0px 4px 10px rgba(4, 45, 120, 0.33);
  }

  .dot-one {
    background-color: #22D7B7;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

}

.header-text {
  color: #1B51DC;
  text-align: center;
  font-size: 5vw;
  margin-top: 5%;
}

.road {
  margin-top: 10%;

  img {
    width: 90%;
  }
}

.aware-text {
  margin-top: 5%;
  text-align: center;
  color: #303D53;
}

.siri {
  canvas {
    width: 100% !important;
  }
}

hr {
  width: 15%;
  position: relative;
  z-index: 2;
  height: 2px !important;
  background-color: #06265A !important;
}

.reminders-image {
  width: 100% !important;
}