.precize {
  margin: 0 auto;
  width: 71%;

  .precize-header {
    font-weight: bold;
    width: 100%;
    font-size: 2vw;
    padding: 0 10%;
    color: #303D53;
    letter-spacing: -0.06em;
  }

  .body {
    display: flex;
    flex-direction: row;
    margin-top: 40px;

    .left-image {
      width: 40%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;

      img {
        width: 80%;
      }
    }

    .desc {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .line {
        width: 6px;
        height: 100%;
        background-color: #4E5F7C;
        border-radius: 15px;
      }

      .desc-one {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: flex-start;
        font-size: 0.9vw;
        margin-bottom: 80px;
      }

      .desc-two {
        margin-top: 23%;
        text-align: right;
        font-size: 0.9vw;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
      }
    }

    .right-image {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: 25%;
      padding-left: 20px;

      .optimize-text {
        font-weight: bold;
        font-size: 1.5vw;
        margin-bottom: 10px;
        color: #303D53;
        letter-spacing: -0.06em;
      }

      img {
        width: 80%;
      }
    }
  }
}