.operation-view {
  margin-top: 60px;
  color: #303D53;

  .header {
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5vw;
    letter-spacing: -0.06em;
  }

  .desc {
    width: 100%;
    text-align: center;
    line-height: 2;
    font-size: 1vw;
  }

  .maps {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 60px auto 0 auto;

    .map-view {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        width: 90%;
        align-self: flex-end;
      }

      .map-view-details {
        width: 85%;
        padding-top: 20px;
        height: 200px;

        .text {
          width: 100%;
          text-align: right;
          color: #1F293B;
          margin-bottom: 20px;
          font-size: 1vw;
        }

        .map-icons {
          width: 100%;
          display: flex;
          flex-direction: row;
          margin-top: -5%;
          height: 90%;

          div {
            width: 20%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
          }

          .change {
            height: 50%;

            img {
              cursor: pointer;
            }
          }

          .dark {
            height: 60%;

            img {
              cursor: pointer;
            }
          }

          .color {
            height: 70%;

            img {
              cursor: pointer;
            }
          }

          .mono {
            height: 80%;

            img {
              cursor: pointer;
            }
          }
        }
      }
    }

    .map-desc {
      width: 40%;
      margin-left: -10%;
      margin-top: 5%;

      img {
        width: 100%;
      }
    }
  }
}