.alerts {
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: row;
  width: 90%;

  .phone {
    width: 30%;

    img {
      width: 100%;
    }
  }

  .alerts-body {
    width: 70%;
    height: 100%;

    .alerts-texts {
      margin: 0 auto 10% auto;
      width: 90%;
      height: 40%;
      display: flex;
      align-items: flex-end;
      flex-direction: row;

      .alerts-text-one {
        width: 40%;

        .alerts-text-one-header {
          font-size: 2.2vw;
          font-weight: bold;
          color: #303D53;
          margin-bottom: 10px;
          letter-spacing: -0.06em;
        }

        .alerts-text-one-body {
          font-size: 1.1vw;
          color: #303D53;
          font-weight: bold;
        }
      }

      .alerts-text-two {
        width: 60%;
        min-height: 95px;
        font-size: 1.1vw;
        color: #303D53;
        text-align: center;
      }
    }

    .alerts-image {
      height: 60%;
      margin-left: -15%;

      img {
        width: 100%;
      }
    }
  }
}