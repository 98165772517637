.communicator {
  padding: 0 5%;
  color: #303D53;

  .communicator-header {
    font-weight: bold;
    font-size: 2.6vw;
    margin-bottom: 20px;
    letter-spacing: -0.06em;
  }

  .communicator-body {
    display: flex;
    flex-direction: row;

    .desc {
      width: 30%;

      .desc-header {
        font-size: 1vw;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .desc-body {
        line-height: 2;
        font-size: 0.8vw;
      }
    }

    .image {
      width: 60%;
      padding-top: 5%;

      img {
        width: 100%;
      }
    }

    .details {
      width: 20%;
      padding-top: 5%;

      img {
        width: 100%;
      }
    }
  }
}